<template>
  <v-container fluid>
    <navbar title="جستجو"/>
      <v-main class="pt-15 d-flex justify-center align-center">
      <error-alert/>
      <div class="search-wrapper">
        <input @keyup="search_product" type="search" placeholder='جستجو...' v-model="search_fild_input" class="search-input">
        <v-icon @click="clear_input" >mdi-close</v-icon>
      </div>
      <v-card class="d-flex card pa-2 mt-3 ">
        <div class="d-flex align-center"  @click="filters = !filters">
          <v-icon class="mr-2">mdi-filter-outline</v-icon>
          <span class="medium-font mr-1">فیلتر</span>
        </div>
        <div @click="sort = !sort" class="d-flex align-center">
          <v-icon class="mr-2">mdi-filter-variant</v-icon>
          <span class="medium-font mr-1">براساس</span>
        </div>
      </v-card>
      <div v-if="fild_filters && fild_filters.has_quantity == 0 || fild_filters && fild_filters.title || fild_filters && fild_filters.sort_field" class="chips-container d-flex align-center mt-1">
        <v-col class="pa-0">
          <v-chip @click:close="remove_filter({name: 'has_quantity'})" v-if="fild_filters && fild_filters.has_quantity == 0" class="ml-1 mb-1" close small>کالاهای ناموجود</v-chip>
          <v-chip @click:close="remove_filter({name:'title', value: fild_filters.title.value})" v-if="fild_filters && fild_filters.title" class="ml-1 mb-1" close small>{{fild_filters.title.title}}</v-chip>
          <v-chip @click:close="remove_filter({name:'sort_field'})" v-if="fild_filters && fild_filters.sort_field == 1" class="ml-1 mb-1" close small>پرفروش ترین</v-chip>
          <v-chip @click:close="remove_filter({name:'sort_field'})" v-if="fild_filters && fild_filters.sort_field == 6" class="ml-1 mb-1" close small>پرتخفیف ترین</v-chip>
        </v-col>
        <!-- <v-col cols="1" class="pa-0">
          <v-icon>mdi-close</v-icon>
        </v-col> -->
      </div>
      <v-row v-if="results.length > 1" class="pa-2 mt-1 d-flex justify-space-between align-center">
        <span class="medium-font muted--text mr-2">نتایج جستجو</span>
        <div v-if="count_result" class="ml-2">
          <span class="medium-font muted--text" v-text="count_result"></span>
          <span class="medium-font muted--text mr-1">کالا</span>
        </div>
      </v-row>
      <div v-if="not_result" class="d-flex flex-column align-center pa-2 mt-6">
        <v-img class="my-3" width="80" src="@/assets/icon/search2.svg"></v-img>
        <span class="medium-font">موردی یافت نشد!</span>
      </div>
      <div class="mt-3 " >
        <result v-for="result_product in results" :key="result_product.id" :result_product = 'result_product'/>
      </div>
      <sort :sort = 'sort'/>
      <sheet :filter= 'filters'/>
      <v-btn @click="more_page" v-if="results.length > 1 && count_result > 12 && count_result != results.length" class="my-3 py-5 button" outlined block>مشاهده بیشتر</v-btn>
      <loading v-if="loading"/>
    </v-main>
  </v-container>
</template>
<style lang="scss" scoped>
.chips-container{
  width: 100%;
  overflow: hidden;
  min-height: 40px;
}
</style>
<script>
import navbar from '@/components/navbar/appBar'
import result from './components/searchResultCard'
import sort from './components/sort'
import sheet from './components/filterButtonSheet'
import loading from '@/components/tools/loadingPage'
import { mapState, Store } from 'vuex'
import store from '@/store'
import { search_product, get_filters } from './models/search'
import { viewbasket_products } from '../Basket/models/Basket'
import ErrorAlert from '../../../components/alerts/error.alert.vue'
export default {
    components: {
        navbar,
        result,
        sort,
        sheet,
        loading,
        ErrorAlert
    },
    computed: {
      ...mapState({
        results: state => state.search.result,
        search_fild: state => state.search.searchFild,
        loading: state => state.search.load_page,
        not_result: state => state.search.not_result,
        filter: state => state.search.filter_search_filds,
        count_result: state => state.search.count_result
      })
    },
    watch: {
      loading: {
        deep: true,
        handler: function () {
          if (this.loading == true) {
            const filters = localStorage.getItem('filter_search')
            if (filters) {
              this.fild_filters = JSON.parse(filters)
            }
            const basket = localStorage.getItem('basket')
        if (basket) {
          const time = new Date()
          const now = time.getTime()
          const old_time_basket = parseInt(localStorage.getItem('time_basket'))
          if ((old_time_basket + 86400000) < now ) {
            localStorage.removeItem('basket')
            viewbasket_products()
          } else {
          store.dispatch('basket_local', JSON.parse(basket))
          }
        } else {
          viewbasket_products()
        }
      }
      }
      }
    },
    data: () => ({
        sort: false,
        filters: false,
        search_fild_input: '',
        fild_filters: {},
        show_basket: false
    }),
    created () {
      store.dispatch('productDetail')
      get_filters()
      let filters = localStorage.getItem('filter_search')
        if (filters) {
          filters = JSON.parse(filters)
          filters.page = 1
           if (filters.has_quantity != 0) {
             filters.has_quantity = 1
           }
          this.search_fild_input = filters.search
          this.fild_filters = filters
          setTimeout(() => {
          search_product(filters)
        }, 800);
          localStorage.setItem('filter_search', JSON.stringify(filters))
        } else {
          filters = {}
          filters.page = 1
          filters.has_quantity = 1
          this.fild_filters = filters
          localStorage.setItem('filter_search', JSON.stringify(filters))
        }
    },
    methods: {
      search_product: function (e) {
        if (e.keyCode == 13) {
          let filters = localStorage.getItem('filter_search')
        if (filters) {
          filters = JSON.parse(filters)
          filters.search = this.search_fild_input
          localStorage.setItem('filter_search', JSON.stringify(filters))
        } else {
          filters = {}
          filters.search = this.search_fild_input
          localStorage.setItem('filter_search', JSON.stringify(filters))
        }
          search_product(filters)
        }
      },
      clear_input () {
        this.search_fild_input.search = ''
      },
      more_page () {
        let filters = localStorage.getItem('filter_search')
        if (filters) {
          filters = JSON.parse(filters)
          filters.page = parseInt(filters.page) + 1
          localStorage.setItem('filter_search', JSON.stringify(filters))
        }
        search_product(filters)
      },
      remove_filter (filter) {
        console.log(filter)
        let filters = localStorage.getItem('filter_search')
        if (filters) {
          filters = JSON.parse(filters)
        } else {
          filters = {}
        }
        switch (filter.name) {
          case 'has_quantity':
            filters.has_quantity = 1
            this.fild_filters.has_quantity = 1
            localStorage.setItem('filter_search', JSON.stringify(filters))
            search_product(filters)
            break;
          case 'title':
            filters[filter.value] = ''
            filters.title = null
            this.fild_filters.title = null
            localStorage.setItem('filter_search', JSON.stringify(filters))
            search_product(filters)
            break;
            case 'sort_field':
            filters.sort_field = ''
            localStorage.setItem('filter_search', JSON.stringify(filters))
            search_product(filters)
            break;
        }
      }
    }
}
</script>