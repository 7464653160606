<template>
  <div class="text-center">
   <v-select :items="items" flat multiple
    class="subtitle-2 blocksFont--text card" hide-details="" label="انتخاب دسته" 
    placeholder="انتخاب دسته" solo>
   </v-select>
  </div>
</template>
<script>
export default {
  data: () => ({
    items: ['کاله', 'میهن', 'مزمز', 'فارسی'],
  })
}
</script>