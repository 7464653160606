<template>
    <div class="d-flex justify-center my-2">
        <div class="ml-4">
          <a type="button">
            <v-icon small class="primary pa-1 rounded-lg" :disabled='loading' @click="addedTobasket()" dark>mdi-plus</v-icon>
          </a>
        </div>
        <div v-if="!loading">
          <span class="primaryText--text" v-text="count"><span v-text="slider.unit_title"></span></span>
        </div>
        <div v-if="loading" style="position: relative">
          <loading/>
        </div>
        <div  class="mr-4">
        <a type="button">
          <v-icon small class="primary pa-1 rounded-lg" :disabled='loading' @click="removebasket()" dark>mdi-minus</v-icon>
        </a>
    </div>
    </div>
</template>
<script>
import { addTObasket, removeTObasket } from '../../../Basket/models/Basket'
import Store from '@/store/index'

import { mapState } from 'vuex'
import loading from '@/components/tools/smallLoading'
import store from '@/store/index'
export default {
  components:{
    loading
  },
  props: ['slider','orders'],
  data: () => ({
    addbasket: 0,
    total: 0,
    count: 0,
    loading: false
  }),
  computed: {
    ...mapState({
      addBasket_status: state => state.basket.addBasket_status,
      addBasket_error: state => state.basket.addBasket_error
    })
  },
  watch: {
    addBasket_status: {
      handler: function () {
        if (this.addBasket_status == true && this.addBasket_error == false) {
          this.count = this.addbasket
          this.loading = false
        } else if (this.addBasket_error == true) {
          this.loading = false
          if (this.addbasket == parseInt(this.$props.slider.min_order)) {
            this.$emit('show',false)
          }
        }
      }
    }
  },
  mounted () {
    if (this.$props.orders.orderd == false) {
      this.loading = true
      this.addbasket= parseInt(this.$props.slider.min_order)
      this.count = this.addbasket
      addTObasket({product: { product_id: this.$props.slider.id ,count: this.addbasket, org_id: this.$props.slider.org_id },
        type: '+',
        price: 0
      })
    } else {
      this.addbasket = parseInt(this.$props.orders.order.count)
      this.count = parseInt(this.$props.orders.order.count)
    }
  },
  methods: {
    addedTobasket () {
      this.loading = true
      Store.dispatch('alertError', {show: false, type: 'capacity'})
      const Max_Order = Math.min(this.$props.slider.onhand, this.$props.slider.max_order)
        if (this.addbasket < Max_Order) {
          setTimeout(() => {
           addTObasket({product: { product_id: this.$props.slider.id ,count: this.addbasket, org_id: this.$props.slider.org_id },
           type: '+',
           price: 0
           })
          }, 700)
            this.addbasket = this.addbasket + parseInt(this.$props.slider.lot_size)
        } else {
          Store.dispatch('alertError', {show: true, type: 'capacity'})
          setTimeout(() => {
            Store.dispatch('alertError', {show: false, type: 'capacity'})
          }, 2500)
        }
      },
    removebasket () {
      this.loading = true
        if (this.addbasket > this.$props.slider.min_order) {
          this.addbasket = this.addbasket - parseInt(this.$props.slider.lot_size)
          setTimeout(() => {
            addTObasket({product: { product_id: this.$props.slider.id ,count: this.addbasket, org_id: this.$props.slider.org_id },
           type: '-',
           price: 0
           })
          }, 700)
        } else {
          removeTObasket({ product_id: this.$props.slider.id, org_id: this.$props.slider.org_id })
          this.$emit('show',false)
        }
    }
  }
}
</script>